<template>
  <div>
    <div class="post-view" v-if="currentBlog">
      <div class="container">
        <h1 class="blog-title">{{ currentBlog.blogTitle }}</h1>
        <h4 class="blog-date">
          Posted on: {{ new Date(currentBlog.date).toLocaleString('en-us', { dateStyle: 'long' }) }}
        </h4>
        <div class="pic-wrapper">
          <img class="blog-pic" :src="currentBlog.blogCoverPhoto" alt="Blog Cover Photo" />
        </div>
        <div class="post-content ql-editor" v-html="currentBlog.blogHTML"></div>
        <div class="like-section">
          <thumbs @click="toggleLike" :class="{ liked: liked }" class="like-icon" />
          <span>{{ currentBlog.likes }} likes</span>
        </div>
        <div class="comments-section">
          <h3>Comments</h3>
          <div class="comment-form">
            <textarea v-model="newComment" placeholder="Write a comment..." rows="3"></textarea>
            <button @click="submitComment" class="submit-comment-button">Post Comment</button>
          </div>
          <div class="comments-list">
            <div class="comment" v-for="comment in comments" :key="comment.id">
              <div class="comment-header">
                <div class="comment-author">{{ comment.authorName }}</div>
                <div class="comment-date">{{ formatTimestamp(comment.timestamp.seconds) }}</div>
                <trash v-if="canDeleteComment(comment)" class="delete-icon" @click="deleteComment(comment.id)" />
              </div>
              <div class="comment-content">{{ comment.content }}</div>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="suggested-section">
        <h2>Reviews You Might Like</h2>
        <div class="reviews-grid">
          <div class="review-card" v-for="review in suggestedReviews" :key="review.id">
            <h4 class="review-title">{{ review.title }}</h4>
            <p class="review-content">{{ review.content }}</p>
          </div>
        </div>
        <h2>Reviews you might like</h2>
        <div class="blogs-grid">
          <div class="blog-card" v-for="blog in recentBlogs" :key="blog.id">
            <h4 class="blog-title">{{ blog.title }}</h4>
            <p class="blog-excerpt">{{ blog.excerpt }}</p>
          </div>
        </div>
      </div>-->
    </div>
    <div v-else class="spinner-container">
      <div class="spinner"></div>
    </div>
  </div>
</template>


<script>
import firebase from 'firebase/app';
import thumbs from '../assets/Icons/thumbs.svg';
import trash from '../assets/Icons/trash-can.svg';
import 'firebase/firestore';
import 'firebase/auth';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

export default {
  name: "ViewBlog",
  components: { thumbs, trash },
  metaInfo() {
    return {
      title: this.currentBlog ? this.currentBlog.blogTitle : 'Loading...',
      meta: this.currentBlog ? [
        { name: 'description', content: this.currentBlog.blogDescription },
        { property: 'og:title', content: this.currentBlog.blogTitle },
        { property: 'og:description', content: this.currentBlog.blogDescription },
        { property: 'og:image', content: this.currentBlog.blogCoverPhoto },
        { property: 'og:url', content: window.location.href },
        { name: 'twitter:card', content: this.currentBlog.blogCoverPhoto },
        { name: 'twitter:title', content: this.currentBlog.blogTitle },
        { name: 'twitter:description', content: this.currentBlog.blogDescription },
        { name: 'twitter:image', content: this.currentBlog.blogCoverPhoto }
      ] : []
    }
  },
  data() {
    return {
      currentBlog: null,
      liked: false,
      comments: [],
      newComment: '',
      suggestedReviews: [],
      recentBlogs: []
    };
  },
  async mounted() {
    const blogId = this.$route.params.blogid;
    const db = firebase.firestore();

    try {
      const doc = await db.collection('blogPosts').doc(blogId).get();
      if (doc.exists) {
        this.currentBlog = doc.data();
      } else {
        console.error('No such document!');
      }
    } catch (error) {
      console.error('Error getting document:', error);
    }

    this.fetchComments();
    //this.fetchSuggestedReviews();
    //this.fetchRecentBlogs();

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userLikesDoc = await db.collection('userLikes').doc(user.uid).get();
        if (userLikesDoc.exists && userLikesDoc.data().likedPosts.includes(blogId)) {
          this.liked = true;
        }
      }
    });
  },
  methods: {
    async toggleLike() {
      const user = firebase.auth().currentUser;
      if (!user) {
        this.$router.push({ name: 'Register' });
        return;
      }

      const blogId = this.$route.params.blogid;
      const db = firebase.firestore();
      const blogRef = db.collection('blogPosts').doc(blogId);

      try {
        const userLikesDoc = await db.collection('userLikes').doc(user.uid).get();
        if (!userLikesDoc.exists) {
          await db.collection('userLikes').doc(user.uid).set({ likedPosts: [] });
        }

        await db.runTransaction(async (transaction) => {
          const doc = await transaction.get(blogRef);
          if (!doc.exists) {
            throw "Blog post does not exist!";
          }

          let data = doc.data();
          let likes = data.likes || 0;

          // Toggle like status
          if (!this.liked) {
            likes++;
            this.liked = true;
          } else {
            likes--;
            this.liked = false;
          }

          transaction.update(blogRef, { likes });
          let userLikes = (await transaction.get(db.collection('userLikes').doc(user.uid))).data().likedPosts;
          if (this.liked) {
            userLikes.push(blogId);
          } else {
            userLikes = userLikes.filter(id => id !== blogId);
          }
          transaction.update(db.collection('userLikes').doc(user.uid), { likedPosts: userLikes });
        });
      } catch (error) {
        console.error('Transaction failed: ', error);
        alert('An error occurred while updating the like status. Please try again.');
      }
    },
    async fetchComments() {
      const blogId = this.$route.params.blogid;
      const db = firebase.firestore();
      try {
        const commentsSnapshot = await db.collection('blogPosts').doc(blogId).collection('comments').orderBy('timestamp', 'desc').get();
        const comments = commentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        for (let comment of comments) {
          const userDoc = await db.collection('users').doc(comment.userId).get();
          if (userDoc.exists) {
            comment.authorName = userDoc.data().userName;
          } else {
            comment.authorName = 'Unknown';
          }
        }

        this.comments = comments;
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    },
    async submitComment() {
      const user = firebase.auth().currentUser;
      if (!user) {
        this.$router.push({ name: 'Register' });
        return;
      }

      const blogId = this.$route.params.blogid;
      const db = firebase.firestore();
      const commentRef = db.collection('blogPosts').doc(blogId).collection('comments').doc();

      try {
        const userDoc = await db.collection('users').doc(user.uid).get();
        const userName = userDoc.exists ? userDoc.data().userName : 'Anonymous';

        await commentRef.set({
          authorName: userName,
          content: this.newComment,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          userId: user.uid
        });

        this.fetchComments();
        this.newComment = ''; // Clear the textarea
      } catch (error) {
        console.error('Error submitting comment:', error);
      }
    },
    async fetchSuggestedReviews() {
      const db = firebase.firestore();
      try {
        const reviewsSnapshot = await db.collection('reviews').orderBy('rating', 'desc').limit(3).get();
        this.suggestedReviews = reviewsSnapshot.docs.map(doc => doc.data());
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    },
    async fetchRecentBlogs() {
      const db = firebase.firestore();
      try {
        const blogsSnapshot = await db.collection('blogPosts').orderBy('date', 'desc').limit(3).get();
        this.recentBlogs = blogsSnapshot.docs.map(doc => doc.data());
      } catch (error) {
        console.error('Error fetching recent blogs:', error);
      }
    },
    formatTimestamp(seconds) {
      const date = new Date(seconds * 1000);
      return date.toLocaleString();
    },
    canDeleteComment(comment) {
      const currentUser = firebase.auth().currentUser;
      // Check if the current user is the author of the comment or an admin
      return currentUser && (comment.userId === currentUser.uid || currentUser.isAdmin);
    },
    async deleteComment(commentId) {
      const db = firebase.firestore();
      const blogId = this.$route.params.blogid;
      const commentRef = db.collection('blogPosts').doc(blogId).collection('comments').doc(commentId);

      try {
        await commentRef.delete();
        // Remove the deleted comment from the local array
        this.comments = this.comments.filter(comment => comment.id !== commentId);
      } catch (error) {
        console.error('Error deleting comment:', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.post-view {
  font-family: 'Georgia', 'Times New Roman', Times, serif;
  padding: 32px 16px;
  background: #f4f4f4;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 32px;
  background: #fff;
  border-radius: 10px;
}

.blog-title {
  font-size: 2.5rem;
  margin-bottom: 16px;
  color: #333;
  font-weight: bold;
  line-height: 1.2;
}

.blog-date {
  font-size: 1rem;
  margin-bottom: 32px;
  color: #888;
  font-style: italic;
}

.pic-wrapper {
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
}

.blog-pic {
  width: 100%;
  height: auto;
  max-height: 450px;
  object-fit: contain; /* Changed from cover to contain */
  border-radius: 10px;
}

.post-content {
  font-size: 1.125rem;
  line-height: 1.8;
  color: #333;
  word-break: break-word;
}

.like-section {
  display: flex;
  align-items: center;
  margin-top: 20px;

  .like-icon {
    cursor: pointer;
    width: 38px;
    fill: #8F80BF;
    height: auto;
    transition: transform 0.3s ease, color 0.3s ease;

    &:hover {
      transform: scale(1.2);
      fill: #1C1C20;
    }

    &.liked {
      fill: #e74c3c; /* Changed color on liked state */
    }
  }

  span {
    margin-left: 8px;
    font-size: 1.125rem;
    color: #555;
  }
}

.comments-section {
  margin-top: 40px;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .comment-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    textarea {
      resize: none;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      margin-bottom: 10px;
      font-size: 1rem;
      font-family: inherit;
      line-height: 1.5;
    }

    .submit-comment-button {
      background-color: #3498db;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 1rem;
      align-self: flex-end;

      &:hover {
        background-color: #2980b9;
      }
    }
  }

  .comments-list {
    .comment {
      background: #f9f9f9;
      padding: 15px;
      border-radius: 5px;
      margin-bottom: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .comment-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .comment-author {
          font-weight: bold;
          margin-bottom: 5px;
        }

        .comment-date {
          font-size: 0.875rem;
          color: #888;
        }

        .delete-icon {
          cursor: pointer;
          width: 22px;
          height: auto;
          fill: #101110;

          &:hover {
            fill: #c0392b;
            transform: scale(1.2);
          }
        }
      }

      .comment-content {
        margin-top: 5px;
        font-size: 1rem;
        color: #555;
      }
    }
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.suggested-section {
  padding: 32px;
  background-color: #f4f4f4;
}

.reviews-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.review-card {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-card {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blogs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.review-title {
  font-size: 1.25rem;
  color: #333;
}

.review-content {
  font-size: 1rem;
  color: #555;
}

.blog-title {
  font-size: 1.25rem;
  color: #333;
}

.blog-excerpt {
  font-size: 1rem;
  color: #555;
}

@media (max-width: 768px) {
  .container {
    padding: 16px;
  }

  .blog-title {
    font-size: 2rem;
  }

  .blog-date {
    font-size: 0.9rem;
  }

  .blog-pic {
    max-height: 300px;
  }

  .suggested-section {
    padding: 16px;
  }

  .review-card,
  .blog-card {
    padding: 16px;
  }
}
</style>
